import React from "react";
import { NavItem } from "react-bootstrap";
function RightBar(props) {
  ///console.log(props);
  return (
    <React.Fragment>
      <div className="flex" id="content">
        <div className="padding">
          <div className="d-flex justify-content-between">
            <div className="paddingLeft">
              {props.leftContent ? props.leftContent : null}
            </div>
            <div className="paddingRight">
              {props.rightContent ? props.rightContent : null}
            </div>
          </div>
          {props.htmlContent}
        </div>
      </div>
    </React.Fragment>
  );
}

export default RightBar;
