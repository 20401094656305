import env from "./env";

const API_URL = {
  LOGINURL_USER: `${env.BASE_URL}/user/signin`,
  LOGINURL_ADMIN: `${env.BASE_URL}/admin/signin`,

  //Signup
  SIGNUP_USER: `${env.BASE_URL}/admin/add-user`,
  RESEND_USER_EMAIL_VERIFY: `${env.BASE_URL}/admin/resend-email-verify`,

  // User Dashboard
  GET_ALL_REPORTS: `${env.BASE_URL}/admin/all-requests`,
  GET_FILE_REPORT: `${env.BASE_URL}/admin/get-requests-list`,
  DELETE_REPORTS: `${env.BASE_URL}/admin/delete-requests`,
  GET_USER_BY_ID: `${env.BASE_URL}/admin/user-by-id`,
  UPDATE_USER: `${env.BASE_URL}/admin/update-user`,
  UPDATE_ADMIN_USER: `${env.BASE_URL}/admin/update-admin-user`,
  UPDATE_STATUS: `${env.BASE_URL}/admin/update-user-status`,
  CHANGE_EMAIL: `${env.BASE_URL}/admin/change-user-email`,
  CHANGE_PASSWORD: `${env.BASE_URL}/admin/change-user-pass`,
  USER_FORGET_PASSWORD: `${env.BASE_URL}/admin/forgot-password-mail`,
  USER_RESET_PASSWORD: `${env.BASE_URL}/admin/reset-password`,
  USER_RESEND_FORGOT_PASSWORD: `${env.BASE_URL}/admin/resend-email-forgotpass`,

  // Admin Dashboard
  GET_ADMIN_BY_ID: `${env.BASE_URL}/admin/admin-by-id`,
  GET_USER_LIST: `${env.BASE_URL}/admin/user-list`,
  DELETE_USER: `${env.BASE_URL}/admin/delete-user`,
  ADMIN_FORGET_PASSWORD: `${env.BASE_URL}/admin/admin-forgot-password-mail`,
  ADMIN_RESET_PASSWORD: `${env.BASE_URL}/admin/admin-reset-password`,
  ADMIN_RESEND_FORGOT_PASSWORD: `${env.BASE_URL}/admin/admin-resend-email-forgotpass`,

  //strategy permission
  GET_STRATEGY_LIST: `${env.BASE_URL}/admin/st-permission-list?limit=10&page=`,
  UPDATE_PERMISSION: `${env.BASE_URL}/admin/update-st-permission`,
};

export default API_URL;
