import { CloseOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Button, Drawer, Form, Input, Modal, Switch } from "antd";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import userImage from "../../assets/images/userImg.jpeg";
import { useNavigate } from "react-router-dom";
import mydecodedTokenFunction from "../../Utils/decodedtoken";
import axios from "axios";
import API_URL from "../../Constants/api-path";
import { toast } from "react-toastify";
import moment from "moment";
import _debounce from "lodash/debounce";
import Spinner from "react-bootstrap/Spinner";
import LogoutModal from "../LogoutModal";
import secureLocalStorage from "react-secure-storage";

function Profile({ visible, invisible, type, open, close }) {
  let history = useNavigate();
  const userid = mydecodedTokenFunction(localStorage.getItem("token"));
  let currentPassword = secureLocalStorage.getItem("User_Details");
  //console.log(currentPassword);
  const [tab, setTab] = useState("profile");

  const [auth, setAuth] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEmailModalVisible, setIsEmailModalVisible] = useState(false);
  const [drawVisible, setDrawVisible] = useState(false);
  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState(false);
  const [readOnly, setReadOnly] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [activeLogs, setActivelogs] = useState();
  const [emailLogs, setEmailLogs] = useState();
  const [loading1, setloading1] = useState(false);
  const [authStatus, setAuthStatus] = useState();
  const [toggle, settoggle] = useState(false);
  const [toggle2, settoggle2] = useState(false);
  const [payload, setpayload] = useState();
  const [ButtonLoader, setButtonLoader] = useState(false);
  const [firstNameloader, setFirstnameLoader] = useState(false);
  const [lastNameloader, setLastnameLoader] = useState(false);
  const [oldPassError, setOldpassError] = useState("");
  const [datas, setdatas] = useState({
    Mobile_num: "",
    first_name: "",
    last_name: "",
  });
  const [dataType, setDataType] = useState("");
  const [updateEmail, setUpdateEmail] = useState({
    old_email: "",
    new_email: "",
    user_id: userid.id,
  });
  const [updatedPassword, setUpdatedPassword] = useState({
    verify_password: "",
    old_password: "",
    new_password: "",
    user_id: userid.id,
  });
  const [passwordError, setPasswordError] = useState("");

  //console.log(payload);
  const token = localStorage.getItem("token");
  useEffect(() => {
    // Update the document title using the browser API
    setDrawVisible(visible);
  });

  useEffect(() => {
    getUser();
  }, []);

  const getUser = () => {
    axios
      .get(`${API_URL.GET_ADMIN_BY_ID}/${userid.id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        //console.log("Response Data", res.data);
        setUserData(res.data.data[0]);
        setActivelogs(res.data.data[0].activity_log);
        setEmailLogs(res.data.data[0].email_activity_log);
        setAuthStatus(res.data.data[0].auth_2fa);
        setLoading(true);
        if (res.data.data[0].auth_2fa === true) {
          setAuth("enable");
        } else {
          setAuth("Disabled");
        }
        setpayload({
          email: res.data.data[0].email,
          first_name: res.data.data[0].first_name,
          last_name: res.data.data[0].last_name,
          phone_no: res.data.data[0].phone_no,
          activity_log: res.data.data[0].activity_log,
          email_activity_log: res.data.data[0].email_activity_log,
          auth_2fa: res.data.data[0].auth_2fa,
        });
        if (
          res.data.data[0].first_name !== undefined &&
          res.data.data[0].last_name !== undefined
        ) {
          res.data.data[0].name
            ? localStorage.setItem(
                "name",
                JSON.stringify(res.data.data[0].name)
              )
            : localStorage.setItem(
                "name",
                JSON.stringify(
                  `${res.data.data[0].first_name} ${res.data.data[0].last_name}`
                )
              );
        } else {
          localStorage.setItem(
            "name",
            JSON.stringify(res.data.data[0].email[0])
          );
        }
      })
      .catch((error) => {
        //console.log("here");
        console.log(error);
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      });
  };
  //console.log(loading);

  const getname = () => {
    if (userData.first_name !== undefined && userData.last_name !== undefined) {
      return userData.name
        ? userData.name
        : `${userData.first_name} ${userData.last_name}`;
    } else {
      return userData.email[0];
    }
  };

  const makeEditable = () => {
    if (readOnly) {
      document.querySelector(".updateProfileButtn").style.backgroundColor =
        "rgb(64,116,249)";
      document.querySelector(".updateProfileButtn").style.color = "white";
      setReadOnly(false);
      setDisabled(false);
    } else {
      document.querySelector(".updateProfileButtn").style.backgroundColor =
        "#E7E7E7";

      setReadOnly(true);
      setDisabled(true);
    }
  };

  const updateProfile = () => {
    //console.log("here");
    // console.log(activeLogs);
    // console.log(emailLogs);
    //console.log(authStatus);
    if (loading) {
      let payload = {
        email: userData.email,
        first_name: userData.first_name,
        last_name: userData.last_name,
        phone_no: userData.phone_no,
        activity_log: activeLogs,
        email_activity_log: emailLogs,
        auth_2fa: authStatus,
      };
      //console.log(payload);
      setButtonLoader(true);
      axios
        .put(`${API_URL.UPDATE_ADMIN_USER}/${userid.id}`, payload, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          settoggle(false);
          setButtonLoader(false);
          console.log("Updated Data", res.data);
          if (res.data.message === "User data update successfully.") {
            return toast.success("updated successfully.", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 1000,
            });
          }
        })
        .catch((error) => {
          setButtonLoader(false);
          console.log(error);
          return toast.error("something went wrong server error", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        });
    }
  };

  const onChange = (checked, label) => {
    //console.log(`switch to ${label} ${checked}`);
    loading1 ? setloading1(false) : setloading1(true);
    settoggle(true);

    if (label === "activeLogs") {
      setActivelogs(checked);
    } else if (label === "emailLogs") {
      setEmailLogs(checked);
    } else {
      //console.log(checked);
      if (checked === "enable") {
        setAuthStatus(false);
      } else {
        setAuthStatus(true);
      }
    }
  };
  useEffect(() => {
    //console.log(loading);
    if (loading && toggle) {
      updateProfile();
    }
  }, [loading1]);

  // Debouncing Functionality
  const debounceFirstNameFn = useCallback(
    _debounce(handledebounceFirstNameFn, 2000),
    []
  );
  const debounceLastNameFn = useCallback(
    _debounce(handledebounceLastNameFn, 2000),
    []
  );

  const handleChangeFirstName = (value) => {
    setFirstnameLoader(true);
    settoggle2(true);
    setDataType("FirstName");
    debounceFirstNameFn(value);
  };

  function handledebounceFirstNameFn(inputValue) {
    setdatas({ ...datas, first_name: inputValue });
  }

  const handleChangeLastName = (value) => {
    setLastnameLoader(true);
    settoggle2(true);
    setDataType("LastName");
    debounceLastNameFn(value);
  };

  function handledebounceLastNameFn(inputValue) {
    setdatas({ ...datas, last_name: inputValue });
  }

  useEffect(() => {
    //console.log(dataType);
    const updateData = (temp) => {
      axios
        .put(`${API_URL.UPDATE_ADMIN_USER}/${userid.id}`, temp, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          setFirstnameLoader(false);
          setLastnameLoader(false);
          settoggle2(false);
          if (res.data.message === "User data update successfully.") {
            return toast.success("updated successfully.", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
            });
          }
        })
        .catch((error) => {
          setFirstnameLoader(false);
          setLastnameLoader(false);
          settoggle2(false);
          console.log(error);
          return toast.error("something went wrong server error", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        });
    };
    if (loading && toggle2) {
      if (dataType == "Mobile") {
        let temp = { ...payload, phone_no: datas.Mobile_num };
        updateData(temp);
      } else if (dataType === "FirstName") {
        let temp = { ...payload, first_name: datas.first_name };
        updateData(temp);
      } else {
        let temp = { ...payload, last_name: datas.last_name };
        updateData(temp);
      }
    }
  }, [datas]);

  //Change Email ID

  const onChangeEmail = (e) => {
    setUpdateEmail({ ...updateEmail, [e.target.name]: e.target.value });
  };

  const updateEmailID = () => {
    //console.log(updateEmail);

    if (updateEmail.new_email === "" || updateEmail.old_email === "") {
      return toast.warning("All fields are mandatory", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    }
    setButtonLoader(true);
    axios
      .post(`${API_URL.CHANGE_EMAIL}`, updateEmail, {
        headers: { Authorization: `Bearer ${token}` },
      })

      .then((res) => {
        setButtonLoader(false);
        setIsEmailModalVisible(false);
        console.log("Updated Data", res.data);
        setTimeout(() => {
          history("/");
        }, 3000);
        return toast.success(res.data.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      })
      .catch((error) => {
        setButtonLoader(false);
        console.log(error);
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      });
  };

  //Change Password

  const onChangePassword = (e) => {
    let list = { ...updatedPassword, [e.target.name]: e.target.value };
    CheckpasswordError(list);
    setUpdatedPassword({ ...updatedPassword, [e.target.name]: e.target.value });
  };

  const CheckpasswordError = (list) => {
    if (list.old_password !== currentPassword.password) {
      return setOldpassError("Invalid password");
    } else {
      setOldpassError("");
    }
  };

  const updatedPass = () => {
    if (updatedPassword.new_password !== updatedPassword.verify_password) {
      return;
    }
    // console.log(updatedPassword);

    if (updatedPassword.old_password !== currentPassword.password) {
      return setOldpassError("Invalid password");
    } else {
      setOldpassError("");
    }

    if (
      updatedPassword.old_password === "" ||
      updatedPassword.new_password === ""
    ) {
      return toast.warning("All fields are mandatory", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    }

    var minMaxLength = /^[\s\S]{8,16}$/,
      upper = /[A-Z]/,
      lower = /[a-z]/,
      number = /[0-9]/;

    if (
      minMaxLength.test(updatedPassword.new_password) &&
      upper.test(updatedPassword.new_password) &&
      lower.test(updatedPassword.new_password) &&
      number.test(updatedPassword.new_password)
    ) {
      setPasswordError("");
    } else {
      return setPasswordError(
        "Password Should contain atleast one Uppercase, Lowercase and Numeric"
      );
    }

    let payload = {
      old_password: updatedPassword.old_password,
      new_password: updatedPassword.new_password,
      user_id: updatedPassword.user_id,
    };

    setButtonLoader(true);

    axios
      .post(`${API_URL.CHANGE_PASSWORD}`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })

      .then((res) => {
        setButtonLoader(true);
        setIsModalVisible(false);
        secureLocalStorage.setItem("User_Details", {
          ...currentPassword,
          password: updatedPassword.new_password,
        });
        setTimeout(() => {
          history("/");
        }, 3000);
        console.log("Updated Data", res.data);
        return toast.success(res.data.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
      })
      .catch((error) => {
        setButtonLoader(true);
        console.log(error);
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      });
  };

  return (
    <React.Fragment>
      <Drawer
        placement={"left"}
        width={"40%"}
        visible={drawVisible}
        className="leftDrawers"
        closable={false}
      >
        <div className={tab === "profile" ? "scrollingSv" : ""}>
          <div>
            <div className="row">
              <div className="col-12">
                <div className="drawerHeader text-end">
                  <CloseOutlined
                    className="closeIcon"
                    onClick={() => invisible("Profile")}
                  />
                </div>
              </div>

              <div className="col-11 mt-4">
                <div className="d-flex justify-content-between align-items-center">
                  <Button
                    className="updateProfileButtn d-flex align-items-center"
                    onClick={makeEditable}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 22 21"
                      fill="none"
                      className="me-2"
                    >
                      <path
                        d="M9.86084 6.22003L7.82355 8.25732C7.07297 9.0079 6.3181 9.75848 5.57181 10.5176C5.39596 10.6978 5.263 10.938 5.21153 11.1739C4.94132 12.4134 4.67969 13.6529 4.41806 14.8925L4.27223 15.5787C4.20361 15.9132 4.29368 16.2478 4.521 16.4751C4.69685 16.651 4.94132 16.7496 5.19866 16.7496C5.27158 16.7496 5.34449 16.741 5.41741 16.7282L6.17227 16.5695C7.3732 16.3164 8.57842 16.0591 9.77935 15.8103C10.0581 15.7503 10.294 15.6259 10.4956 15.4243C13.8668 12.0488 17.238 8.67764 20.6092 5.31076C20.8579 5.06199 20.9952 4.76605 21.0295 4.40148C21.0338 4.34143 21.0295 4.28139 21.0166 4.22563C20.9952 4.13985 20.978 4.04978 20.9566 3.964C20.9094 3.74526 20.8579 3.4965 20.755 3.25631C20.1245 1.8152 19.078 0.785828 17.6454 0.206809C17.3581 0.0910052 17.0535 0.0524039 16.7876 0.0180917L16.7147 0.00951359C16.3287 -0.0376657 15.977 0.0910052 15.6725 0.399815C13.7424 2.33846 11.7995 4.28139 9.86084 6.22003ZM16.5732 1.04317C16.5818 1.04317 16.586 1.04317 16.5946 1.04317L16.6675 1.05175C16.8906 1.07748 17.0964 1.10322 17.2594 1.17184C18.426 1.64363 19.2838 2.48428 19.8028 3.66806C19.8629 3.80531 19.9015 3.98116 19.9401 4.16558C19.9529 4.23421 19.9701 4.30283 19.983 4.37146C19.9658 4.44866 19.9358 4.50013 19.8757 4.55589C16.5003 7.92278 13.1291 11.2982 9.7579 14.6694C9.70215 14.7252 9.65068 14.7509 9.57348 14.7681C8.36826 15.0211 7.16733 15.2742 5.96211 15.5272L5.32734 15.6602L5.44743 15.0983C5.70906 13.8631 5.97069 12.6236 6.23661 11.3883C6.24519 11.3497 6.2795 11.2897 6.3181 11.2468C7.06439 10.4919 7.81068 9.74132 8.56126 8.99503L10.5986 6.95774C12.5415 5.01481 14.4844 3.07188 16.4231 1.12466C16.5045 1.05604 16.5432 1.04317 16.5732 1.04317Z"
                        fill="#9A9090"
                      />
                      <path
                        d="M1.66843 4.67592H9.15708C9.44873 4.67592 9.68463 4.44002 9.68463 4.14837C9.68463 3.85672 9.44873 3.62082 9.15708 3.62082H1.66843C0.746291 3.62082 0 4.3714 0 5.28925V19.3315C0 20.2537 0.75058 21 1.66843 21H15.7064C16.6286 21 17.3749 20.2494 17.3749 19.3315V12.1474C17.3749 11.8558 17.139 11.6199 16.8473 11.6199C16.5557 11.6199 16.3198 11.8558 16.3198 12.1474V19.3315C16.3198 19.6704 16.041 19.9492 15.7021 19.9492H1.66843C1.3296 19.9492 1.05081 19.6704 1.05081 19.3315V5.29354C1.05081 4.95471 1.3296 4.67592 1.66843 4.67592Z"
                        fill="#9A9090"
                      />
                    </svg>
                    <span>Update Profile</span>
                  </Button>
                  <Button
                    className="allBtns"
                    style={{ margin: "unset" }}
                    onClick={() => close("Logout")}
                  >
                    Logout
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              {loading ? (
                <div className="row">
                  <div className="col-11 mt-4">
                    <div className="profileInf">
                      <div className="d-flex align-items-center">
                        <div className="userImageWrppr">
                          {/* <img src={userImage} className="userImage" /> */}
                          <img
                            className="userImage"
                            width="40px"
                            src={
                              "https://ui-avatars.com/api/?&rounded=false&font-size=0.38&background=EFEFEF&color=4074f9&name=" +
                              getname()
                            }
                          />
                        </div>
                        <div className="ms-3">
                          <>
                            <h5>
                              {userData.first_name !== undefined
                                ? userData.name
                                  ? userData.name
                                  : `${userData.first_name} ${userData.last_name}`
                                : "-"}
                            </h5>
                          </>

                          <p>{userData.email ? userData.email : "-"}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div className="row">
                <div className="col-11 mt-4">
                  <div className="d-flex">
                    <div
                      className={
                        tab === "profile"
                          ? "profileSettingTab active"
                          : "profileSettingTab"
                      }
                      onClick={() => setTab("profile")}
                    >
                      Personal Details
                    </div>
                    <div
                      className={
                        tab === "security"
                          ? "profileSettingTab active"
                          : "profileSettingTab"
                      }
                      onClick={() => setTab("security")}
                    >
                      Security Setting
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {loading ? (
              <>
                <div
                  className={
                    tab === "profile"
                      ? "personalDetails"
                      : "personalDetails d-none"
                  }
                >
                  <div className="row">
                    <div className="col-11 mt-3">
                      <div>
                        <p style={{ color: "#9B8E8E" }}>
                          Basic info, like your name and address
                        </p>
                        <div>
                          <Form className="mt-4" layout="vertical">
                            <Form.Item
                              label="First Name"
                              name="First_name"
                              rules={[
                                {
                                  required: true,
                                  message: "Name is required",
                                },
                              ]}
                            >
                              <div className="d-flex position-relative">
                                <Input
                                  className="form-control"
                                  placeholder="Enter name"
                                  defaultValue={
                                    userData.first_name
                                      ? userData.name
                                        ? userData.name
                                        : `${userData.first_name}`
                                      : "-"
                                  }
                                  readOnly={readOnly}
                                  name="First_name"
                                  onChange={(e) =>
                                    handleChangeFirstName(e.target.value)
                                  }
                                />
                                {firstNameloader ? (
                                  <Spinner
                                    animation="border"
                                    size="sm"
                                    className="position-absolute right-0"
                                    style={{
                                      marginTop: "10px",
                                      marginRight: "10px",
                                    }}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </Form.Item>

                            <Form.Item
                              label="Last Name"
                              name="Last_name"
                              rules={[
                                {
                                  required: true,
                                  message: "Name is required",
                                },
                              ]}
                            >
                              <div className="d-flex position-relative">
                                <Input
                                  className="form-control"
                                  placeholder="Enter name"
                                  defaultValue={
                                    userData.last_name
                                      ? userData.name
                                        ? userData.name
                                        : `${userData.last_name}`
                                      : "-"
                                  }
                                  readOnly={readOnly}
                                  name="Last_name"
                                  onChange={(e) =>
                                    handleChangeLastName(e.target.value)
                                  }
                                />
                                {lastNameloader ? (
                                  <Spinner
                                    animation="border"
                                    size="sm"
                                    className="position-absolute right-0"
                                    style={{
                                      marginTop: "10px",
                                      marginRight: "10px",
                                    }}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </Form.Item>

                            <Form.Item
                              label="Email Id"
                              name="email"
                              rules={[
                                {
                                  required: true,
                                  message: "Email is required",
                                },
                              ]}
                            >
                              <Input
                                className="form-control"
                                placeholder="Enter email"
                                defaultValue={
                                  userData.email ? userData.email : "-"
                                }
                                name="email"
                                readOnly={true}
                                // onChange={(e) => updateprofileEmail(e)}
                              />
                            </Form.Item>

                            <Form.Item
                              label="Phone Number"
                              name="phone"
                              rules={[
                                {
                                  required: true,
                                  message: "Phone is required",
                                },
                              ]}
                            >
                              <Input
                                className="form-control"
                                placeholder="Enter contact details"
                                defaultValue={
                                  userData.phone_no ? userData.phone_no : "-"
                                }
                                readOnly={true}
                                name="phoneNum"
                              />
                            </Form.Item>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="additionalDetails">
                    <p style={{ fontSize: "12px" }}>Additional</p>
                    <div className="d-flex justify-content-between">
                      <div>
                        <p className="idw">User Id:</p>
                        <p className="ded">{userid.id}</p>
                      </div>
                      <div>
                        <p className="idw">Last Login:</p>
                        <p className="ded">
                          {moment(userData.last_login).format("DD MMM, YYYY")}
                        </p>
                      </div>
                      <div>
                        <p className="idw">Register At:</p>
                        <p className="ded">
                          {moment(userData.created_at).format("DD MMM, YYYY")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={
                    tab === "security"
                      ? "personalDetails securities"
                      : "personalDetails securities d-none"
                  }
                >
                  <div className="row">
                    <div className="col-11 mt-3">
                      <div>
                        <p style={{ color: "#9B8E8E" }}>
                          These settings are helps you keep your account Secure.
                        </p>

                        <div className="mt-4">
                          <div className="d-flex justify-content-between sdsee">
                            <div>
                              <h5 className="seTitle">Save My Activity Logs</h5>
                              <p className="sePara">
                                Save your all activity logs includong unsual
                                activity detected
                              </p>
                            </div>
                            <div>
                              <Switch
                                defaultChecked={activeLogs}
                                disabled={disabled}
                                onChange={(checked) => {
                                  return onChange(checked, "activeLogs");
                                }}
                              />
                            </div>
                          </div>

                          <div className="d-flex justify-content-between sdsee">
                            <div>
                              <h5 className="seTitle">
                                Email me if encounter unusual activity
                              </h5>
                              <p className="sePara">
                                You will get email notification whenever
                                encounter invalid login activity
                              </p>
                            </div>
                            <div>
                              <Switch
                                defaultChecked={emailLogs}
                                disabled={disabled}
                                onChange={(checked) => {
                                  return onChange(checked, "emailLogs");
                                }}
                              />
                            </div>
                          </div>

                          <div className="d-flex justify-content-between sdsee">
                            <div>
                              <h5 className="seTitle">Change Email Address</h5>
                              <p className="sePara">
                                Update your current email address to new email
                                address.
                              </p>
                            </div>
                            <div>
                              <Button
                                className="securtyBttn"
                                onClick={() => setIsEmailModalVisible(true)}
                                disabled={disabled}
                              >
                                Change Email
                              </Button>
                            </div>
                          </div>

                          <div className="d-flex justify-content-between sdsee">
                            <div>
                              <h5 className="seTitle">Change Password</h5>
                              <p className="sePara">
                                Set a unique password to protect your account.
                              </p>
                            </div>
                            <div className="d-flex align-items-center">
                              <span
                                style={{
                                  fontSize: "10px",
                                  color: "#9B8E8E",
                                  fontStyle: "italic",
                                }}
                              >
                                Last Change N/A
                              </span>
                              <Button
                                className="securtyBttn ms-3"
                                onClick={() => setIsModalVisible(true)}
                                disabled={disabled}
                              >
                                Change Password
                              </Button>
                            </div>
                          </div>

                          <div className="d-flex justify-content-between sdsee">
                            <div>
                              <h5 className="seTitle">
                                2FA Authentication{" "}
                                <Button
                                  className={
                                    auth === "enable"
                                      ? "authenticationStatus enabled"
                                      : "authenticationStatus"
                                  }
                                  disabled={disabled}
                                >
                                  {auth === "enable" ? "Enabled" : "Disabled"}
                                </Button>
                              </h5>
                              <p className="sePara">
                                Secure your account with 2FA security. When it
                                is activated you will need to enter not only
                                password, but also a special code using your
                                mobile
                              </p>
                            </div>
                            <div className="d-flex align-items-center">
                              <Button
                                className="securtyBttn ms-3"
                                onClick={() => {
                                  if (auth === "enable") {
                                    setAuth("Disabled");
                                  } else {
                                    setAuth("enable");
                                  }
                                  return onChange(auth, "Auth");
                                }}
                                disabled={disabled}
                                loading={ButtonLoader}
                              >
                                {auth === "enable" ? "Disable" : "Enable"}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </Drawer>

      <Modal
        footer={null}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
      >
        <div className="row mt-4">
          <div className="col-11">
            <h5>Change Password</h5>
          </div>
          <div className="col-11">
            <Form className="mt-3" layout="vertical">
              <Form.Item
                label="Current Password"
                name="currentPassword"
                rules={[
                  {
                    required: true,
                    message: "Password is required",
                  },
                ]}
              >
                <Input.Password
                  placeholder="Enter current password"
                  name="old_password"
                  onChange={onChangePassword}
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    height: "40px",
                    borderRadius: "0.375rem",
                  }}
                />
              </Form.Item>
              {oldPassError ? (
                <>
                  <div className="ant-form-item-explain ant-form-item-explain-connected">
                    <div
                      className="ant-form-item-explain-error"
                      style={{ marginTop: "0px" }}
                    >
                      {oldPassError}
                    </div>
                  </div>
                </>
              ) : null}

              <Form.Item
                label="New Password"
                name="newPassword"
                rules={[
                  {
                    required: true,
                    message: "New Password is required",
                  },
                ]}
              >
                <Input.Password
                  placeholder="Enter new password"
                  name="new_password"
                  onChange={onChangePassword}
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    height: "40px",
                    borderRadius: "0.375rem",
                  }}
                />
              </Form.Item>
              {passwordError ? (
                <>
                  <div className="ant-form-item-explain ant-form-item-explain-connected">
                    <div
                      className="ant-form-item-explain-error"
                      style={{ marginTop: "0px" }}
                    >
                      {passwordError}
                    </div>
                  </div>
                </>
              ) : null}
              <Form.Item
                label="Verify Password"
                name="verifyPassword"
                dependencies={["newPassword"]}
                rules={[
                  {
                    required: true,
                    message: "Password field required",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("newPassword") === value) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  placeholder="Enter verify password"
                  name="verify_password"
                  onChange={onChangePassword}
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    height: "40px",
                    borderRadius: "0.375rem",
                  }}
                />
              </Form.Item>
              <div className="d-flex align-items-center">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="allBtns me-3"
                  onClick={updatedPass}
                >
                  Update Password
                </Button>
                <a
                  onClick={() => setIsModalVisible(false)}
                  style={{ color: "#8C7E7E" }}
                >
                  Cancel
                </a>
              </div>
            </Form>
          </div>
        </div>
      </Modal>

      <Modal
        footer={null}
        visible={isEmailModalVisible}
        onCancel={() => setIsEmailModalVisible(false)}
      >
        <div className="row mt-4">
          <div className="col-11">
            <h5>Enter Your Valid Email Address</h5>
          </div>
          <div className="col-11">
            <Form className="mt-3" layout="vertical">
              <Form.Item
                label="Current Email Address"
                name="currentEmail"
                rules={[
                  {
                    required: true,
                    message: "Email is required",
                  },
                ]}
              >
                <Input
                  className="form-control"
                  placeholder="Enter Current Email"
                  name="old_email"
                  onChange={onChangeEmail}
                />
              </Form.Item>

              <Form.Item
                label="New Email Address"
                name="newEmailAddress"
                rules={[
                  {
                    required: true,
                    message: "New Email is required",
                  },
                ]}
              >
                <Input
                  className="form-control"
                  placeholder="Enter new email"
                  name="new_email"
                  onChange={onChangeEmail}
                />
              </Form.Item>

              <div className="d-flex align-items-center mt-4">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="allBtns me-3"
                  onClick={updateEmailID}
                  loading={ButtonLoader}
                >
                  Send Verification Email
                </Button>
                <a
                  onClick={() => setIsEmailModalVisible(false)}
                  style={{ color: "#8C7E7E" }}
                >
                  Cancel
                </a>
              </div>
            </Form>
            <small
              className="mt-4 d-block"
              style={{ color: "red", fontSize: "9px" }}
            >
              {" "}
              <InfoCircleOutlined className="me-2" />
              Wether you verify your email or not, from nect login you have to
              use your new email address.
            </small>
          </div>
        </div>
      </Modal>
      <LogoutModal open={open} close={close} type={type} />
    </React.Fragment>
  );
}

export default Profile;
