import {
  ArrowDownOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  ArrowUpOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  MoreOutlined,
  PlusOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Menu, Pagination, Switch, Table } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Leftbar from "../../../Component/Admin/Leftbar";
import RightBar from "../../../Component/RightBar";
import UpdatedTime from "../../../Component/UpdatedTime";
import userImg from "../../../assets/images/userImg.jpeg";
import { ImLink } from "react-icons/im";
import { VscEdit } from "react-icons/vsc";
import axios from "axios";
import { toast } from "react-toastify";
import API_URL from "../../../Constants/api-path";
function UserProfile() {
  let { id } = useParams();
  console.log(id);
  let token = localStorage.getItem("token");
  const [userData, setUserData] = useState({});

  useEffect(() => {
    getUser();
  }, []);

  const getUser = () => {
    axios
      .get(`${API_URL.GET_USER_BY_ID}/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        console.log("Response Data", res.data);
        setUserData(res.data.data[0]);
      })
      .catch((error) => {
        console.log(error);
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      });
  };

  const [edit, setEdit] = useState(false);
  const [tab, changeTab] = useState("overview");
  const [invoiceTab, setInvoiceTab] = useState("thisYear");

  const actionMenu = (
    <Menu
      items={[
        {
          label: (
            <a href="#" className="d-flex align-items-center">
              Approved
            </a>
          ),
          key: "0",
        },
        {
          label: (
            <a href="#" className="d-flex align-items-center">
              Processing
            </a>
          ),
          key: "1",
        },

        {
          label: (
            <a href="#" className="d-flex align-items-center">
              Rejected
            </a>
          ),
          key: "2",
        },
      ]}
    />
  );

  const dataSource = [
    {
      invoiceNumber: "8618-9358",
      status: <div className="status success">Successful</div>,
      amount: "$1,200.00",
      date: "14 Dec 2020, 8:43 pm",
      action: (
        <Dropdown
          overlay={actionMenu}
          placement="bottomLeft"
          arrow
          overlayClassName="allReportsWrapper"
        >
          <Button className="d-flex align-items-center paymentTableDropdown">
            Action <RightOutlined className="rightOutLined" />
          </Button>
        </Dropdown>
      ),
    },
    {
      invoiceNumber: "8618-9358",
      status: <div className="status pending">Pending</div>,
      amount: "$1,200.00",
      date: "14 Dec 2020, 8:43 pm",
      action: (
        <Dropdown
          overlay={actionMenu}
          placement="bottomLeft"
          arrow
          overlayClassName="allReportsWrapper"
        >
          <Button className="d-flex align-items-center paymentTableDropdown">
            Action <RightOutlined className="rightOutLined" />
          </Button>
        </Dropdown>
      ),
    },
  ];

  const invoiceDataSource = [
    {
      derId: "8618-9358",
      amount: (
        <span style={{ color: "#DA4B4B", fontWeight: "600" }}>-$1200</span>
      ),
      status: <div className="status success">Successful</div>,
      date: "14 Dec 2020, 8:43 pm",
      invoice: (
        <Button className="d-flex align-items-center paymentTableDropdown">
          Download
        </Button>
      ),
    },
    {
      derId: "8618-9358",
      amount: (
        <span style={{ color: "#43A233", fontWeight: "600" }}>$1200</span>
      ),
      status: <div className="status pending">Pending</div>,
      date: "14 Dec 2020, 8:43 pm",
      invoice: (
        <Button className="d-flex align-items-center paymentTableDropdown">
          Download
        </Button>
      ),
    },
    {
      derId: "8618-9358",
      amount: (
        <span style={{ color: "#43A233", fontWeight: "600" }}>$1200</span>
      ),
      status: <div className="status progres">Progress</div>,
      date: "14 Dec 2020, 8:43 pm",
      invoice: (
        <Button className="d-flex align-items-center paymentTableDropdown">
          Download
        </Button>
      ),
    },
  ];

  const invoiceColumn = [
    {
      title: "DER ID",
      dataIndex: "derId",
    },
    {
      title: "AMOUNT",
      dataIndex: "amount",
    },
    {
      title: "STATUS",
      dataIndex: "status",
    },
    {
      title: "DATE",
      dataIndex: "date",
    },
    {
      title: "INVOICE",
      dataIndex: "invoice",
    },
  ];

  const itemRender: PaginationProps["itemRender"] = (
    _,
    type,
    originalElement
  ) => {
    if (type === "prev") {
      return <ArrowLeftOutlined />;
    }
    if (type === "next") {
      return <ArrowRightOutlined />;
    }
    return originalElement;
  };

  const columns = [
    {
      title: "INVOICE NO.",
      dataIndex: "invoiceNumber",
    },
    {
      title: "STATUS",
      dataIndex: "status",
    },
    {
      title: "AMOUNT",
      dataIndex: "amount",
    },
    {
      title: "DATE",
      dataIndex: "date",
    },
    {
      title: "ACTION",
      dataIndex: "action",
    },
  ];

  const allUsersMenu = (
    <Menu
      items={[
        {
          label: (
            <span
              style={{
                color: "#8F8888",
                fontWeight: "600",
                display: "block",
                marginTop: "20px",
              }}
            >
              PAYMENTS
            </span>
          ),
          key: "0",
        },
        {
          label: <a href="#">Create invoice</a>,
          key: "1",
        },
        {
          label: (
            <div className="d-flex align-items-center justify-content-between">
              <a href="#">Create payments</a>
              <InfoCircleOutlined />
            </div>
          ),
          key: "2",
        },

        {
          label: (
            <div className="d-flex align-items-center justify-content-between">
              <a href="#">Subscription</a>
              <RightOutlined />
            </div>
          ),
          key: "3",
        },

        {
          label: (
            <span
              style={{
                color: "#8F8888",
                fontWeight: "600",
                marginTop: "20px",
                display: "block",
              }}
            >
              ACCOUNTS
            </span>
          ),
          key: "4",
        },

        {
          label: (
            <div className="d-flex align-items-center justify-content-between">
              <a href="#">Reports</a>
            </div>
          ),
          key: "5",
        },

        {
          label: (
            <div className="d-flex align-items-center justify-content-between">
              <a href="#">Account Setting</a>
            </div>
          ),
          key: "6",
        },

        {
          label: (
            <div className="d-flex align-items-center justify-content-between">
              <a href="#" className="maroon">
                Delete Customer
              </a>
            </div>
          ),
          key: "6",
        },
      ]}
    />
  );

  return (
    <React.Fragment>
      <div className="dashboardView">
        <div className="layout-row flex" id="main">
          <Leftbar type="admin" />
          <RightBar
            leftContent={
              <>
                <div className="d-flex align-items-center">
                  <li>
                    <div className="leftContent">
                      {" "}
                      Account Id: {userData?._id}
                    </div>
                  </li>
                </div>
              </>
            }
            rightContent={
              <>
                <UpdatedTime invokeFunction={""} loading={""} />
              </>
            }
            htmlContent={
              <React.Fragment>
                <div className="headingSection userDetailsSection mb-4 mt-4 d-flex justify-content-between align-items-center">
                  <div>
                    <h2 className="d-flex align-items-center">
                      <span className="heading">User Details</span>
                    </h2>
                    <p className="subHead">
                      <Link to={"/dashboard"}>List of Users</Link> - User
                      Details
                    </p>
                  </div>

                  <div className="d-flex align-items-center">
                    <div className="me-4">
                      <div className="printerIcon userSide">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="21"
                          viewBox="0 0 36 21"
                          fill="currentColor"
                          className="iconSize24"
                        >
                          <path d="M12 19.5C12 19.1022 12.158 18.7206 12.4393 18.4393C12.7206 18.158 13.1022 18 13.5 18H22.5C22.8978 18 23.2794 18.158 23.5607 18.4393C23.842 18.7206 24 19.1022 24 19.5C24 19.8978 23.842 20.2794 23.5607 20.5607C23.2794 20.842 22.8978 21 22.5 21H13.5C13.1022 21 12.7206 20.842 12.4393 20.5607C12.158 20.2794 12 19.8978 12 19.5ZM6 10.5C6 10.1022 6.15804 9.72064 6.43934 9.43934C6.72064 9.15804 7.10218 9 7.5 9H28.5C28.8978 9 29.2794 9.15804 29.5607 9.43934C29.842 9.72064 30 10.1022 30 10.5C30 10.8978 29.842 11.2794 29.5607 11.5607C29.2794 11.842 28.8978 12 28.5 12H7.5C7.10218 12 6.72064 11.842 6.43934 11.5607C6.15804 11.2794 6 10.8978 6 10.5ZM0 1.5C0 1.10218 0.158036 0.720645 0.43934 0.43934C0.720645 0.158036 1.10218 0 1.5 0H34.5C34.8978 0 35.2794 0.158036 35.5607 0.43934C35.842 0.720645 36 1.10218 36 1.5C36 1.89782 35.842 2.27936 35.5607 2.56066C35.2794 2.84196 34.8978 3 34.5 3H1.5C1.10218 3 0.720645 2.84196 0.43934 2.56066C0.158036 2.27936 0 1.89782 0 1.5Z" />
                        </svg>
                      </div>
                    </div>
                    <Dropdown
                      overlay={allUsersMenu}
                      placement="bottomLeft"
                      arrow
                      trigger={"click"}
                      overlayClassName="allUserDropdown"
                    >
                      <Button className="d-flex align-items-center allReports">
                        All Users <RightOutlined className="rightOutLined" />
                      </Button>
                    </Dropdown>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-lg-5 col-xxl-4">
                    <div className="userProfileCard">
                      <div className="text-center">
                        <img
                          src={`https://eu.ui-avatars.com/api/?name=${userData?.first_name}${userData?.last_name}&size=250`}
                          className="userProfileImg"
                        />
                        <h5 className="mt-3">
                          {`${userData?.first_name} ${userData?.last_name}`}
                        </h5>
                        <p className="mt-1" style={{ color: "#949494" }}>
                          {userData?.signup_type}
                        </p>
                      </div>

                      <div className="d-flex justify-content-between align-items-center mt-4">
                        <div className="statesDashedBrdr">
                          <h6
                            className="d-flex align-items-center"
                            style={{ color: "#ACABAB", fontWeight: "600" }}
                          >
                            6900{" "}
                            <ArrowUpOutlined
                              className="ms-3"
                              style={{ color: "#43A233" }}
                            />
                          </h6>
                          <p>Earnings</p>
                        </div>
                        <div className="statesDashedBrdr">
                          <h6
                            className="d-flex align-items-center"
                            style={{ color: "#ACABAB", fontWeight: "600" }}
                          >
                            130{" "}
                            <ArrowDownOutlined
                              className="ms-3"
                              style={{ color: "red" }}
                            />
                          </h6>
                          <p>Earnings</p>
                        </div>
                        <div className="statesDashedBrdr">
                          <h6
                            className="d-flex align-items-center"
                            style={{ color: "#ACABAB", fontWeight: "600" }}
                          >
                            500{" "}
                            <ArrowUpOutlined
                              className="ms-3"
                              style={{ color: "#43A233" }}
                            />
                          </h6>
                          <p>Earnings</p>
                        </div>
                      </div>

                      <div className="detials mt-4">
                        <div className="d-flex justify-content-between align-items-center detailsSection">
                          <h4>Details</h4>
                          <a
                            href="#"
                            onClick={() => setEdit(true)}
                            className={edit ? "editIcon active" : "editIcon"}
                          >
                            Edit
                          </a>
                        </div>
                        <div className="detailsDiv">
                          <a className="userType">Premium User</a>

                          <div className="accountDetails">
                            <h6>Account Id</h6>
                            <p>ID-{userData?._id}</p>
                          </div>

                          <div className="accountDetails">
                            <h6>Billing Email</h6>
                            <p>{userData?.email}</p>
                          </div>

                          <div className="accountDetails">
                            <h6>Billing Address</h6>
                            <p>{userData?.address ? userData?.address : "-"}</p>
                          </div>
                          <div className="accountDetails">
                            <h6>Language</h6>
                            <p>
                              {userData?.language ? userData?.language : "-"}
                            </p>
                          </div>
                          <div className="accountDetails">
                            <h6>Last Paid</h6>
                            <p>
                              {userData?.last_paid ? userData?.last_paid : "-"}
                            </p>
                          </div>
                          <div className="accountDetails">
                            <h6>Tax Id</h6>
                            <p>TX-8674</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="userProfileCard">
                      <h4>Connected Brokers</h4>
                      <div className="privacyPolicy d-flex align-items-center mt-4">
                        <ImLink className="icon50" />
                        <p className="ms-3 contentP">
                          By connecting an account, you hereby agree to our
                          <a href="#"> privacy policy</a> and{" "}
                          <a href="#">terms of use</a>.
                        </p>
                      </div>

                      <div className="brokersDetails mt-4">
                        <div className="broker">
                          <div className="row align-items-center">
                            <div className="col-9 col-md-10">
                              <div className="d-flex align-items-center">
                                <div className="brokerLogo"></div>
                                <div className="brokerName">
                                  <h5>Zerodha</h5>
                                  <p>Plan Properly Your Work Follow</p>
                                </div>
                              </div>
                            </div>

                            <div className="col-3 col-md-2 text-right">
                              <Switch defaultChecked={"checked"} />
                            </div>
                          </div>
                        </div>

                        <div className="broker">
                          <div className="row align-items-center">
                            <div className="col-9 col-md-10">
                              <div className="d-flex align-items-center">
                                <div className="brokerLogo"></div>
                                <div className="brokerName">
                                  <h5>Angel Broking</h5>
                                  <p>Keep eye on on your Repositories</p>
                                </div>
                              </div>
                            </div>

                            <div className="col-3 col-md-2 text-right">
                              <Switch defaultChecked={"checked"} />
                            </div>
                          </div>
                        </div>

                        <div className="broker">
                          <div className="row align-items-center">
                            <div className="col-9 col-md-10">
                              <div className="d-flex align-items-center">
                                <div className="brokerLogo"></div>
                                <div className="brokerName">
                                  <h5>Angel One</h5>
                                  <p>Integrate Projects Discussions</p>
                                </div>
                              </div>
                            </div>

                            <div className="col-3 col-md-2 text-right">
                              <Switch defaultChecked={"checked"} />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="text-right mt-4">
                        <Button className="saveBttn">Save Changes</Button>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-lg-7 col-xxl-8">
                    <div className="customTabs">
                      <div className="tabsSe">
                        <li onClick={() => changeTab("overview")}>
                          <span className={tab === "overview" ? "active" : ""}>
                            Overview
                          </span>
                        </li>
                        <li onClick={() => changeTab("events")}>
                          <span className={tab === "events" ? "active" : ""}>
                            Events & Logs
                          </span>
                        </li>

                        <li onClick={() => changeTab("statements")}>
                          <span
                            className={tab === "statements" ? "active" : ""}
                          >
                            Statements
                          </span>
                        </li>
                      </div>
                    </div>

                    <div className="userProfileCard mt-5">
                      <div className="d-flex align-items-center justify-content-between">
                        <h5>Payment Records</h5>
                        <Button className="d-flex align-items-center addPaymentButton">
                          <PlusOutlined className="me-1" />
                          Add payments
                        </Button>
                      </div>

                      <div className="paymentTable mt-4">
                        <Table
                          className="paymentTbl"
                          dataSource={dataSource}
                          columns={columns}
                          pagination={false}
                        />
                      </div>
                      <div className="text-right">
                        <Pagination
                          className="mt-4 cstmPagination"
                          hideOnSinglePage={false}
                          current={1}
                          pageSize={10}
                          total={20}
                          itemRender={itemRender}
                        />
                      </div>
                    </div>

                    <div className="userProfileCard">
                      <div className="d-flex align-items-center justify-content-between">
                        <h5>Payment Methods</h5>
                        <Button className="d-flex align-items-center addPaymentButton">
                          <PlusOutlined className="me-1" />
                          Add payment Methods
                        </Button>
                      </div>

                      <div className="paymentCards mt-4">
                        <div className="d-flex align-items-center justify-content-between">
                          <div style={{ width: "50%" }}>
                            <div className="d-flex align-items-center">
                              <div className="cardImg"></div>
                              <div
                                className="cardDetails ms-2"
                                style={{ width: "50%" }}
                              >
                                <h6
                                  style={{
                                    width: "100%",
                                    position: "relative",
                                  }}
                                >
                                  Mastercard{" "}
                                  <span className="status primary">
                                    Primary
                                  </span>
                                </h6>
                                <p>Expires Dec 2024</p>
                              </div>
                            </div>
                          </div>

                          <div>
                            <div className="d-flex align-items-center">
                              <VscEdit className="cardIcons" />
                              <DeleteOutlined className="cardIcons" />
                              <MoreOutlined className="cardIcons" />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="paymentCards">
                        <div className="d-flex align-items-center justify-content-between">
                          <div style={{ width: "50%" }}>
                            <div className="d-flex align-items-center">
                              <div className="cardImg"></div>
                              <div
                                className="cardDetails ms-2"
                                style={{ width: "50%" }}
                              >
                                <h6
                                  style={{
                                    width: "100%",
                                    position: "relative",
                                  }}
                                >
                                  Visa{" "}
                                </h6>
                                <p>Expires Dec 2024</p>
                              </div>
                            </div>
                          </div>

                          <div>
                            <div className="d-flex align-items-center">
                              <VscEdit className="cardIcons" />
                              <DeleteOutlined className="cardIcons" />
                              <MoreOutlined className="cardIcons" />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="paymentCards">
                        <div className="d-flex align-items-center justify-content-between">
                          <div style={{ width: "50%" }}>
                            <div className="d-flex align-items-center">
                              <div className="cardImg"></div>
                              <div
                                className="cardDetails ms-2"
                                style={{ width: "50%" }}
                              >
                                <h6
                                  style={{
                                    width: "100%",
                                    position: "relative",
                                  }}
                                >
                                  Rupay{" "}
                                  <span className="status expired">
                                    Expired
                                  </span>
                                </h6>
                                <p>Expires Dec 2024</p>
                              </div>
                            </div>
                          </div>

                          <div>
                            <div className="d-flex align-items-center">
                              <VscEdit className="cardIcons" />
                              <DeleteOutlined className="cardIcons" />
                              <MoreOutlined className="cardIcons" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="userProfileCard">
                      <div className="d-flex align-items-center justify-content-between">
                        <h5>Portfolio Balance</h5>
                        <Button className="d-flex align-items-center addPaymentButton">
                          <VscEdit className="me-1" />
                          Adjust Balance
                        </Button>
                      </div>

                      <div className="mt-4">
                        <h4>
                          $32,487.57{" "}
                          <span style={{ color: "#A3A3A3", fontSize: "18px" }}>
                            USD
                          </span>
                        </h4>
                        <p>
                          Balance will increase the amount due on the customer's
                          next invoice.
                        </p>
                      </div>
                    </div>

                    <div className="userProfileCard">
                      <div className="d-flex align-items-center justify-content-between">
                        <h5>Invoices</h5>
                        <div className="d-flex align-items-center invoiceTabs">
                          <li onClick={() => setInvoiceTab("thisYear")}>
                            <span
                              className={
                                invoiceTab === "thisYear" ? "active" : ""
                              }
                            >
                              This Year
                            </span>
                          </li>
                          <li onClick={() => setInvoiceTab("2021")}>
                            <span
                              className={invoiceTab === "2021" ? "active" : ""}
                            >
                              2021
                            </span>
                          </li>
                          <li onClick={() => setInvoiceTab("2020")}>
                            <span
                              className={invoiceTab === "2020" ? "active" : ""}
                            >
                              2020
                            </span>
                          </li>
                          <li onClick={() => setInvoiceTab("2019")}>
                            <span
                              className={invoiceTab === "2019" ? "active" : ""}
                            >
                              2019
                            </span>
                          </li>
                        </div>
                      </div>

                      <div className="paymentTable mt-4">
                        <Table
                          className="paymentTbl"
                          dataSource={invoiceDataSource}
                          columns={invoiceColumn}
                          pagination={false}
                        />
                      </div>
                      <div className="text-right">
                        <Pagination
                          className="mt-4 cstmPagination"
                          hideOnSinglePage={false}
                          current={1}
                          pageSize={10}
                          total={20}
                          itemRender={itemRender}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            }
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default UserProfile;
