import React, { useState, useEffect } from "react";

const Time = () => {
  const formatAMPM = () => {
    var hours = new Date().getHours();
    var minutes = new Date().getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;

    return strTime;
  };
  const date = new Date(Date.now());

  // Request a weekday along with a long date
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const [timeDate, setTimeDate] = useState(
    `${formatAMPM()} | ${date.toLocaleString("en-GB", options)}`
  );
  const showDateTime = () => {
    setTimeDate(`${formatAMPM()} | ${date.toLocaleString("en-GB", options)}`);
  };
  useEffect(() => {
    let interval = setInterval(() => {
      showDateTime();
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [timeDate]);

  return timeDate;
};

export default Time;
