import { Button, Checkbox, Form, Input } from "antd";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../../assets/images/logo.svg";
import dark_logo from "../../../assets/images/logo_black.svg";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import API_URL from "../../../Constants/api-path";
import secureLocalStorage from "react-secure-storage";

function AdminLogin() {
  let value = secureLocalStorage.getItem("Admin_Details");
  const [email, setEmail] = React.useState(value ? value.email : "");
  const [password, setPassword] = React.useState(value ? value.password : "");
  const [loading, setLoading] = React.useState(false);
  const [PassError, setPassError] = React.useState("");
  const [EmailError, setEmailError] = React.useState("");
  const [rememberme, setRememberMe] = useState(false);
  let history = useNavigate();
  // const history = useHistory();

  const loginHandle = () => {
    if (email !== "" && password !== "") {
      if (password.length >= 8) {
        setLoading(true);
        axios
          .post(`${API_URL.LOGINURL_ADMIN}`, {
            email: email,
            password: password,
          })
          .then((res) => {
            // console.log({botData: res});
            setLoading(false);
            console.log("Response Data", res.data);
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("allbots", res.data.bot_list);

            if (res.data.message === "Invalid password") {
              setPassError("Invalid Password");
            } else if (res.data.message === "Email not registered") {
              setEmailError("Email not registered");
            }

            if (res.data.status) {
              //   history.push('/home');
              history("/dashboard");
              setTimeout(() => {
                return toast.success("Login Successful!", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  autoClose: 3000,
                });
              }, 100);
            }
            if (rememberme) {
              secureLocalStorage.setItem("Admin_Details", {
                email: email,
                password: password,
              });
            } else {
              if (value.email === "" && value.password === "") {
                secureLocalStorage.setItem("Admin_Details", {
                  email: "",
                  password: "",
                });
              }
            }
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
            return toast.error(error.response.data.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 3000,
            });
          });
      } else {
        setPassError("Passwords must contain at least eight characters.");
      }
    } else {
      if (password === "" && email === "") {
        setPassError("Password field required");
        setEmailError("Email field required");
      } else if (email === "") {
        setEmailError("Email field required");
      } else if (password === "") {
        setPassError("Password field required");
      }
      return toast.warning("All fields are mandatory", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const handleEmail = (e) => {
    if (EmailError.length > 0) {
      setEmailError("");
    }
    setEmail(e.target.value);
    console.log(email);
  };

  const handlePassword = (e) => {
    if (PassError.length > 0) {
      setPassError("");
    }
    setPassword(e.target.value);
    console.log(password);
  };

  return (
    <React.Fragment>
      <section className="loginSection">
        <div className="logoOverlay d-none d-lg-block">
          <img src={logo} />
        </div>
        <div className="container">
          <div className="loginPage">
            <div className="row justify-content-center">
              <div className="col-12">
                {/* <div className="d-flex align-items-center justify-content-end">
                  <a href="#" className="ndHl me-3">
                    Need Help?
                  </a>
                  <Link className="adminLogin" to={"/"}>
                    User Login
                  </Link>
                </div> */}
              </div>
              <div className="col-12 col-lg-4 col-xl-3">
                <div className="d-lg-none text-center">
                  <img className="mobile_logo" src={dark_logo} />
                </div>
              </div>
              <div className="col-12 col-lg-6 col-xl-4">
                <div className="text-center mt-5">
                  <h1>Admin Login</h1>
                </div>

                <Form className="mt-5" layout="vertical">
                  <Form.Item label="EMAIL" name="email">
                    <Input
                      className="form-control"
                      placeholder="Enter your mail"
                      name="email"
                      type="email"
                      value={email}
                      onChange={handleEmail}
                      defaultValue={value ? value.email : ""}
                    />
                    {EmailError ? (
                      <>
                        <div className="ant-form-item-explain ant-form-item-explain-connected">
                          <div
                            className="ant-form-item-explain-error"
                            style={{ marginTop: "0px" }}
                          >
                            {EmailError}
                          </div>
                        </div>
                      </>
                    ) : null}
                  </Form.Item>

                  <Form.Item label="PASSWORD" name="password">
                    <Input
                      className="form-control"
                      placeholder="Enter your password"
                      name="password"
                      type="password"
                      value={password}
                      onChange={handlePassword}
                      defaultValue={value ? value.password : ""}
                    />
                    {PassError ? (
                      <>
                        <div className="ant-form-item-explain ant-form-item-explain-connected">
                          <div
                            className="ant-form-item-explain-error"
                            style={{ marginTop: "0px" }}
                          >
                            {PassError}
                          </div>
                        </div>
                      </>
                    ) : null}
                  </Form.Item>
                  <div className="d-flex align-items-center justify-content-between">
                    <Form.Item
                      name="remember"
                      valuePropName="checked"
                      className="mb-0"
                    >
                      <Checkbox
                        className="rememberMe"
                        onClick={() =>
                          rememberme
                            ? setRememberMe(false)
                            : setRememberMe(true)
                        }
                      >
                        Remember me
                      </Checkbox>
                    </Form.Item>
                    <a className="forgotPassword" href="/forgot-password">
                      Forgot Password?
                    </a>
                  </div>
                  <div className="text-center mt-5">
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="allBtns m-auto"
                        onClick={loginHandle}
                        loading={loading}
                        //style={{ width: "100%" }}
                      >
                        {loading ? "      " : "LOG IN"}
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default AdminLogin;
