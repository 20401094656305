import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Drawer, Form, Input, Modal, Switch } from "antd";

const LogoutModal = ({ open, close, type }) => {
  let history = useNavigate();

  const logOut = () => {
    if (type == "admin") {
      localStorage.removeItem("token");
      history("/");
    } else {
      localStorage.removeItem("token");
      history("/");
    }
  };

  return (
    <Modal
      footer={null}
      visible={open}
      onCancel={() => close("Logout")}
      centered
    >
      <div>
        <h3 className="pb-4 pt-4">LogOut?</h3>
        <p className="pb-4">Are you sure want to logout?</p>
        <div className="d-flex justify-content-between align-items-center">
          <a onClick={() => close("Logout")} style={{ color: "#8C7E7E" }}>
            Cancel
          </a>
          <Button
            variant="danger"
            className="allBtns me-3"
            onClick={() => logOut()}
          >
            Logout
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default LogoutModal;
