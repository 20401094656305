import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form, Input } from "antd";
import logo from "../../../assets/images/logo.svg";
import dark_logo from "../../../assets/images/logo_black.svg";
import { Link } from "react-router-dom";
import axios from "axios";
import API_URL from "../../../Constants/api-path";
import { toast } from "react-toastify";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [EmailError, setEmailError] = useState("");
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  console.log(email);
  const forgetPassword = () => {
    if (email !== "") {
      setLoading(true);
      axios
        .post(`${API_URL.ADMIN_FORGET_PASSWORD}`, {
          email: email,
        })
        .then((res) => {
          setTimeout(() => {
            setEmail("");
          }, 1000);
          setLoading(false);
          if (res.data.message === "Email not registered") {
            setEmailError("Email not registered");
          }
          console.log("Forget Password", res);
          localStorage.setItem("EmailID", JSON.stringify(email));
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);

          return toast.error("something went wrong server error", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        });
    } else {
      setEmailError("Email Field required");
    }
  };

  const submitForm = ({ email }) => {
    console.log(email);
    form.resetFields();
  };

  return (
    <React.Fragment>
      <section className="loginSection">
        <div className="logoOverlay d-none d-lg-block">
          <img src={logo} />
        </div>
        <div className="container">
          <div className="loginPage">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="d-flex align-items-center justify-content-end">
                  <a href="#" className="ndHl me-3">
                    Need Help?
                  </a>
                </div>
              </div>
              <div className="col-12 col-lg-4 col-xl-3">
                <div className="d-lg-none text-center">
                  <img className="mobile_logo" src={dark_logo} />
                </div>
              </div>
              <div className="col-12 col-lg-6 col-xl-4">
                <div className="forgotForm">
                  <Form
                    form={form}
                    className="mt-5"
                    layout="vertical"
                    onFinish={submitForm}
                  >
                    <Form.Item label="EMAIL" name="email" value={email}>
                      <Input
                        className="form-control"
                        placeholder="Enter your mail"
                        onChange={(e) => {
                          setEmailError("");
                          return setEmail(e.target.value);
                        }}
                      />
                      {EmailError ? (
                        <>
                          <div className="ant-form-item-explain ant-form-item-explain-connected">
                            <div
                              className="ant-form-item-explain-error"
                              style={{ marginTop: "0px" }}
                            >
                              {EmailError}
                            </div>
                          </div>
                        </>
                      ) : null}
                    </Form.Item>
                    <div className="text-center mt-5">
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="allBtns"
                          onClick={forgetPassword}
                          loading={loading}
                        >
                          {loading ? "        " : "Continue"}
                        </Button>
                      </Form.Item>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default ForgotPassword;
