import { BrowserRouter, Routes, Route } from "react-router-dom";
import React from "react";
import AdminForgotPassword from "./Pages/Login/Admin-login/ForgotPassword";
import AdminResetPassword from "./Pages/Login/Admin-login/ResetPassword";
import AdminLogin from "./Pages/Login/Admin-login/AdminLogin";
import AdminDashboard from "./Pages/Admin/Dashboard/AdminDashboard";
import AdminExpiredLink from "./Pages/Login/Admin-login/ExpiredLink_page";
import UserProfile from "./Pages/Admin/Dashboard/UserProfile";
import PrivateRoute from "./Pages/PrivateRoute/PrivateRoute";
import StrategyPermission from "./Pages/Admin/strategy_permission/strategyPermission";

function App() {
  let token = localStorage.getItem("token") || "";
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<AdminLogin />} />

          <Route path="/forgot-password" element={<AdminForgotPassword />} />
          <Route
            path="/reset-password/:token"
            element={<AdminResetPassword />}
          />
          <Route path="/dashboard" element={<AdminDashboard />} />
          <Route path="/strategy/permission" element={<StrategyPermission />} />
          <Route path="/expired_link" element={<AdminExpiredLink />} />
          <Route path="/user/profile/:id" element={<UserProfile />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
