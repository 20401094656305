import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CaretRightOutlined,
  DeleteOutlined,
  CheckOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Input, Menu, Pagination, Table } from "antd";
import React, { useEffect, useState } from "react";
import Leftbar from "../../../Component/Admin/Leftbar";
import RightBar from "../../../Component/RightBar";
import ReportEmptyIcon from "../../../assets/images/ReportEmptyIcon.svg";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { map as Map, get } from "lodash";
import { toast } from "react-toastify";
import API_URL from "../../../Constants/api-path";
import mydecodedTokenFunction from "../../../Utils/decodedtoken";
import UpdatedTime from "../../../Component/UpdatedTime";
import { decodeToken, isExpired } from "react-jwt";

function StrategyPermission() {
  //const userid = mydecodedTokenFunction(localStorage.getItem("token"));
  let adminid = mydecodedTokenFunction(localStorage.getItem("token")) || "";
  let token = localStorage.getItem("token");
  let expiredToken = isExpired(token) ? true : false;
  let history = useNavigate();
  const [currentpage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [editUserModal, setEditUserModal] = useState(false);
  const [AdduserModal, setAdduserModal] = useState(false);
  const [value, setValue] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRowsDatas, setSelectedRowsDatas] = useState([]);
  const [data, setData] = useState();
  const [data1, setData1] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingdata] = useState(false);

  const [expandSearch, setExpandSearch] = useState(false);

  //console.log(id);
  useEffect(() => {
    if (!expiredToken) {
      getStrategy();
    }
  }, [currentpage]);

  const getStrategy = () => {
    setLoadingdata(true);
    axios
      .get(`${API_URL.GET_STRATEGY_LIST}${currentpage}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        console.log("Response Data", res.data);
        setData(
          res.data.data.map((el, index) => {
            let temp = el;
            temp["index"] = index + 1;
            return temp;
          })
        );
        setData1(res.data);
        setLoading(true);
        setLoadingdata(false);
      })
      .catch((error) => {
        console.log(error);
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      });
  };

  //console.log(data);

  let pageSize = 10;

  const menu = (
    <Menu
      items={[
        {
          label: (
            <Link to={"#"} className="d-flex align-items-center">
              <CheckOutlined className="me-2" />
              Accept
            </Link>
          ),
          key: "0",
        },
        {
          label: (
            <a
              href="#"
              className="d-flex align-items-center"
              onClick={() => {
                return setEditUserModal(true);
              }}
            >
              <CloseCircleOutlined className="me-2" />
              Decline
            </a>
          ),
          key: "1",
        },
      ]}
    />
  );

  const allUsersMenu = (
    <Menu
      items={[
        {
          label: (
            <a href="#" className="d-flex align-items-center">
              Approved
            </a>
          ),
          key: "0",
        },
        {
          label: (
            <a
              href="#"
              className="d-flex align-items-center"
              onClick={() => setShowModal(true)}
            >
              Processing
            </a>
          ),
          key: "1",
        },

        {
          label: (
            <a
              href="#"
              className="d-flex align-items-center"
              onClick={() => setShowModal(true)}
            >
              Rejected
            </a>
          ),
          key: "2",
        },
      ]}
    />
  );

  // table Content
  const listData = (data) => {
    //console.log(data);
    var dataSource = [];
    data.map((item, index) => {
      dataSource.push({
        key: item.index,
        id: item._id,
        strategy_name: (
          <Link to={"#"} className="text-dark">
            {item?.strategyData?.name ? item?.strategyData?.name : ""}
          </Link>
        ),
        user_name: (
          <Link to={"#"} className="text-dark">
            {`${item?.userData?.first_name} ${item?.userData?.last_name}`}
          </Link>
        ),
        createdAt: moment(item.created_at).format("MMM DD, YYYY hh:mm A"),
        status: (
          <>
            <a
              className={`userStatus ${
                item?.permission_status === "pending"
                  ? "bg-warning bg-opacity-50"
                  : item?.permission_status === "accept"
                  ? "bg-success bg-opacity-50"
                  : "bg-danger bg-opacity-50"
              }`}
              //onClick={() => ChangeStatus(item.status, item._id)}
            >
              {item?.permission_status !== "" &&
              item?.permission_status !== null &&
              item?.permission_status !== undefined
                ? item?.permission_status
                : "-"}
            </a>
          </>
        ),

        action: (
          <>
            <Dropdown
              overlay={
                <Menu
                  items={[
                    {
                      label: (
                        <Link
                          to={"#"}
                          className="d-flex align-items-center"
                          onClick={() => {
                            handleEdit("accept", item?._id);
                          }}
                        >
                          <CheckOutlined className="me-2" />
                          Accept
                        </Link>
                      ),
                      key: "0",
                    },
                    {
                      label: (
                        <a
                          href="#"
                          className="d-flex align-items-center"
                          onClick={() => {
                            handleEdit("decline", item?._id);
                          }}
                        >
                          <CloseCircleOutlined className="me-2" />
                          Decline
                        </a>
                      ),
                      key: "1",
                    },
                  ]}
                />
              }
              trigger={["click"]}
            >
              <a>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 35 8"
                  fill="none"
                >
                  <path
                    d="M3.75 7.79545C3.0625 7.79545 2.43466 7.62784 1.86648 7.29261C1.2983 6.9517 0.84375 6.49716 0.502841 5.92898C0.167614 5.3608 0 4.73295 0 4.04545C0 3.35227 0.167614 2.72443 0.502841 2.16193C0.84375 1.59375 1.2983 1.14205 1.86648 0.806818C2.43466 0.465909 3.0625 0.295454 3.75 0.295454C4.44318 0.295454 5.07102 0.465909 5.63352 0.806818C6.2017 1.14205 6.65341 1.59375 6.98864 2.16193C7.32955 2.72443 7.5 3.35227 7.5 4.04545C7.5 4.73295 7.32955 5.3608 6.98864 5.92898C6.65341 6.49716 6.2017 6.9517 5.63352 7.29261C5.07102 7.62784 4.44318 7.79545 3.75 7.79545ZM17.25 7.79545C16.5625 7.79545 15.9347 7.62784 15.3665 7.29261C14.7983 6.9517 14.3438 6.49716 14.0028 5.92898C13.6676 5.3608 13.5 4.73295 13.5 4.04545C13.5 3.35227 13.6676 2.72443 14.0028 2.16193C14.3438 1.59375 14.7983 1.14205 15.3665 0.806818C15.9347 0.465909 16.5625 0.295454 17.25 0.295454C17.9432 0.295454 18.571 0.465909 19.1335 0.806818C19.7017 1.14205 20.1534 1.59375 20.4886 2.16193C20.8295 2.72443 21 3.35227 21 4.04545C21 4.73295 20.8295 5.3608 20.4886 5.92898C20.1534 6.49716 19.7017 6.9517 19.1335 7.29261C18.571 7.62784 17.9432 7.79545 17.25 7.79545ZM30.75 7.79545C30.0625 7.79545 29.4347 7.62784 28.8665 7.29261C28.2983 6.9517 27.8438 6.49716 27.5028 5.92898C27.1676 5.3608 27 4.73295 27 4.04545C27 3.35227 27.1676 2.72443 27.5028 2.16193C27.8438 1.59375 28.2983 1.14205 28.8665 0.806818C29.4347 0.465909 30.0625 0.295454 30.75 0.295454C31.4432 0.295454 32.071 0.465909 32.6335 0.806818C33.2017 1.14205 33.6534 1.59375 33.9886 2.16193C34.3295 2.72443 34.5 3.35227 34.5 4.04545C34.5 4.73295 34.3295 5.3608 33.9886 5.92898C33.6534 6.49716 33.2017 6.9517 32.6335 7.29261C32.071 7.62784 31.4432 7.79545 30.75 7.79545Z"
                    fill="#6F756E"
                  />
                </svg>
              </a>
            </Dropdown>
          </>
        ),
      });
    });

    return dataSource;
  };

  // Table Header
  const columns = [
    {
      title: "STRATEGY NAME",
      dataIndex: "strategy_name",
    },
    {
      title: "USER NAME",
      dataIndex: "user_name",
    },
    {
      title: "CREATED AT",
      dataIndex: "createdAt",
    },
    {
      title: "STATUS",
      dataIndex: "status",
    },

    {
      title: "ACTION",
      dataIndex: "action",
    },
  ];

  const itemRender: PaginationProps["itemRender"] = (
    _,
    type,
    originalElement
  ) => {
    if (type === "prev") {
      return <ArrowLeftOutlined />;
    }
    if (type === "next") {
      return <ArrowRightOutlined />;
    }
    return originalElement;
  };
  const hideModal = () => {
    setShowModal(false);
  };

  const rowSelection = {
    selectedRowKeys,

    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowsDatas(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
    },
    getCheckboxProps: (record) => {
      //console.log(record.id);
      return {
        disabled: record.working != null,
        name: record.id,
      };
    },
  };

  const onSearch = (e) => {
    const reg = new RegExp(e.target.value, "gi");

    console.log(e.target.value);

    const filteredData = Map(data1?.data, (record) => {
      //console.log("record", record);
      const firstName = get(record.userData, "first_name").match(reg);
      const secondName = get(record.userData, "last_name").match(reg);
      const strategy = get(record.strategyData, "name").match(reg);
      if (!firstName && !secondName && !strategy) {
        return null;
      }
      return record;
    }).filter((record) => !!record);
    //console.log(filteredData);
    setData(filteredData);
  };

  const handleEdit = (status, id) => {
    let payload = {
      permission_status: status,
      st_id: id,
    };

    axios
      .put(`${API_URL.UPDATE_PERMISSION}`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        getStrategy();
        return toast.success(res.data.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      })
      .catch((error) => {
        console.log(error);
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      });
  };

  if (!expiredToken) {
    if (loading) {
      return (
        <React.Fragment>
          <div className="dashboardView">
            <div className="layout-row flex" id="main">
              <Leftbar type="admin" />
              <RightBar
                leftContent={
                  <>
                    <div className="d-flex align-items-center">
                      <li>
                        <div className="leftContent">
                          {" "}
                          Account Id: {adminid.id}
                        </div>
                      </li>
                    </div>
                  </>
                }
                rightContent={
                  <>
                    <UpdatedTime
                      invokeFunction={getStrategy}
                      loading={loading}
                    />
                  </>
                }
                htmlContent={
                  <React.Fragment>
                    <div className="headingSection mb-4 mt-4">
                      <h2 className="d-flex align-items-center">
                        <span className="heading">List Of Strategy</span>
                      </h2>
                      <p className="subHead">
                        You have total {data1?.countData} strategy.
                      </p>
                    </div>
                    <div className="headerSection mb-3">
                      <div className="row align-items-center pb-2">
                        <div className="col-12 col-md-5 d-hidden">
                          {/* <Dropdown
                            overlay={allUsersMenu}
                            placement="bottomLeft"
                            arrow
                            overlayClassName="allReportsWrapper"
                          >
                            <Button className="d-flex align-items-center allReports">
                              All Users{" "}
                              <CaretRightOutlined className="rightOutLined" />
                            </Button>
                          </Dropdown> */}
                        </div>
                        <div className="col-12 col-md-7 mt-2 mb-2">
                          <div className="d-flex align-items-center justify-content-end">
                            <div
                              className={
                                expandSearch
                                  ? "me-3 animateSearch active mt-2 mb-2"
                                  : "me-3 animateSearch mt-2 mb-2"
                              }
                            >
                              <Input.Group compact className="searchGroup">
                                <Input.Search
                                  allowClear
                                  placeholder="Search"
                                  onChange={onSearch}
                                  onPressEnter={onSearch}
                                  onSearch={() => setExpandSearch(true)}
                                />
                              </Input.Group>
                            </div>
                            {/* <div className="me-3">
                              <div className="printerIcon userSide">
                                <svg
                                  className="iconSize24"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="1em"
                                  height="1em"
                                  viewBox="0 0 35 31"
                                  fill="currentColor"
                                >
                                  <path d="M33.1338 10.5039C32.4657 10.5039 31.9244 11.0454 31.9244 11.7134V28.3743H2.41884V11.7134C2.41884 11.0454 1.87751 10.5039 1.20942 10.5039C0.541337 10.5039 0 11.0453 0 11.7134V29.5838C0 30.2517 0.541337 30.7932 1.20942 30.7932H33.1338C33.8019 30.7932 34.3432 30.2517 34.3432 29.5838V11.7134C34.3432 11.0453 33.8017 10.5039 33.1338 10.5039Z" />
                                  <path d="M16.3144 23.1788C16.5413 23.4057 16.8489 23.5331 17.1698 23.5331C17.4905 23.5331 17.798 23.4057 18.0251 23.1788L23.2454 17.9583C23.7177 17.486 23.7177 16.7202 23.2454 16.248C22.7729 15.7755 22.0073 15.7757 21.535 16.248L18.3793 19.4036L18.38 1.20942C18.38 0.541498 17.8386 0 17.1706 0C16.5026 0 15.9611 0.541498 15.9611 1.20942L15.9605 19.4038L12.8086 16.2517C12.3363 15.7794 11.5706 15.7792 11.0981 16.2517C10.6258 16.724 10.6258 17.4897 11.0981 17.962L16.3144 23.1788Z" />
                                </svg>
                              </div>
                            </div> */}
                            {/* <div className="me-3">
                              <div className="printerIcon userSide">
                                <DeleteOutlined
                                  className="iconSize24"
                                  onClick={() => setShowModal(true)}
                                />
                              </div>
                            </div> */}
                            {/* <div className="me-3">
                              <div className="printerIcon userSide">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="1em"
                                  height="1em"
                                  viewBox="0 0 36 21"
                                  fill="currentColor"
                                  className="iconSize24"
                                >
                                  <path d="M12 19.5C12 19.1022 12.158 18.7206 12.4393 18.4393C12.7206 18.158 13.1022 18 13.5 18H22.5C22.8978 18 23.2794 18.158 23.5607 18.4393C23.842 18.7206 24 19.1022 24 19.5C24 19.8978 23.842 20.2794 23.5607 20.5607C23.2794 20.842 22.8978 21 22.5 21H13.5C13.1022 21 12.7206 20.842 12.4393 20.5607C12.158 20.2794 12 19.8978 12 19.5ZM6 10.5C6 10.1022 6.15804 9.72064 6.43934 9.43934C6.72064 9.15804 7.10218 9 7.5 9H28.5C28.8978 9 29.2794 9.15804 29.5607 9.43934C29.842 9.72064 30 10.1022 30 10.5C30 10.8978 29.842 11.2794 29.5607 11.5607C29.2794 11.842 28.8978 12 28.5 12H7.5C7.10218 12 6.72064 11.842 6.43934 11.5607C6.15804 11.2794 6 10.8978 6 10.5ZM0 1.5C0 1.10218 0.158036 0.720645 0.43934 0.43934C0.720645 0.158036 1.10218 0 1.5 0H34.5C34.8978 0 35.2794 0.158036 35.5607 0.43934C35.842 0.720645 36 1.10218 36 1.5C36 1.89782 35.842 2.27936 35.5607 2.56066C35.2794 2.84196 34.8978 3 34.5 3H1.5C1.10218 3 0.720645 2.84196 0.43934 2.56066C0.158036 2.27936 0 1.89782 0 1.5Z" />
                                </svg>
                              </div>
                            </div> */}
                            {/* <div className="me-3">
                              <Button
                                style={{ borderRadius: "10px" }}
                                onClick={() => setAdduserModal(true)}
                              >
                                Add User
                              </Button>
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="tableSection mb-3">
                        <Table
                          dataSource={listData(data)}
                          columns={columns}
                          className="fs_tables"
                          rowSelection={rowSelection}
                          pagination={false}
                          loading={loadingData}
                          locale={{
                            emptyText: (
                              <div className="mt-5 mb-5">
                                <img
                                  style={{ width: "100%", maxWidth: "100px" }}
                                  src={ReportEmptyIcon}
                                  alt="Empty Icon"
                                />
                                <h5 className="mt-4">
                                  No Users Found. Please Generate New Report.
                                </h5>
                              </div>
                            ),
                          }}
                        />
                        <div className="text-center">
                          <Pagination
                            className="mt-4 cstmPagination"
                            hideOnSinglePage={false}
                            current={currentpage}
                            pageSize={10}
                            total={data1.countData}
                            itemRender={itemRender}
                            onChange={setCurrentPage}
                          />
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                }
              />
            </div>
          </div>
        </React.Fragment>
      );
    }
  } else {
    window.location.href = "/";
  }
}

export default StrategyPermission;
